import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Typography } from 'cfa-react-components';
import HistoricalPerformanceChart from '../../../../../components/charts/HistoricalPerformanceChart/HistoricalPerformanceChart';
import { API_GET } from '../../../../../utils/api/API';
import MissingDataToolTip from '../../../../../components/common/MissingDataToolTip/MissingDataToolTip';

const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const compareMonthYear = (a = '', b = '') => {
  const [monthA, yearA] = a.split('-');
  const [monthB, yearB] = b.split('-');

  if (yearA !== yearB) {
    return parseInt(yearB, 10) - parseInt(yearA, 10);
  }

  return monthsShort.indexOf(monthB) - monthsShort.indexOf(monthA);
};

const compareQuarterRounds = (roundA = '', roundB = '') => {
  if (!roundA && !roundB) {
    return 0;
  }

  const [rawQuarterA = '', rawYearA = ''] = roundA.split('-');
  const [rawQuarterB = '', rawYearB = ''] = roundB.split('-');

  // Convert empty year to 0
  const yearA = parseInt(rawYearA, 10) || 0;
  const yearB = parseInt(rawYearB, 10) || 0;

  // Compare years first
  const yearDiff = yearA - yearB;
  if (yearDiff !== 0) {
    return yearDiff;
  }

  // If same year, compare quarter strings
  return rawQuarterA.localeCompare(rawQuarterB);
};

const sortByMonth = (a, b) => compareMonthYear(b?.round, a?.round);
const sortByQuarter = (a, b) => compareQuarterRounds(b?.round, a?.round);

const HistoricalPerformanceCard = ({
  round,
  rounds = [],
  roundsLoading = true,
}) => {
  const { locationNumber } = useParams();
  const [isMissingData, setIsMissingData] = useState(false);
  const [auditSummary, setAuditSummary] = useState(null);
  const [auditSummaryLoading, setAuditSummaryLoading] = useState(true);
  const [auditByLocationLoading, setAuditByLocationLoading] = useState(true);

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (!round && rounds.length === 0 && !roundsLoading) {
      setAuditSummaryLoading(false);
      setAuditByLocationLoading(false);
    }
  }, [
    auditByLocationLoading,
    auditSummaryLoading,
    round,
    rounds,
    roundsLoading,
  ]);

  useEffect(() => {
    if (!locationNumber || !round) return;
    const fetchData = async () => {
      try {
        setIsMissingData(false);
        setAuditByLocationLoading(true);

        // Fetch available rounds from the audit by-location endpoint
        const byLocationPath = `${process.env.REACT_APP_SERVER_URL}/assessment?programs=SAFE&locationNumbers=${locationNumber}`;
        const { data: auditByLocation } = await API_GET(byLocationPath);

        let pointer = rounds.indexOf(round);

        // finding next round if current round is missing
        if (
          auditByLocation.findIndex((x) => x.round === rounds[pointer]) === -1
        ) {
          for (let i = pointer + 1; i < rounds.length; i++) {
            if (
              auditByLocation.findIndex((x) => x.round === rounds[i]) !== -1
            ) {
              pointer = i;
              break;
            }
          }
        }

        setAuditSummaryLoading(true);

        // Fetch audit summary data for the selected round
        const auditSummaryPath = `${process.env.REACT_APP_SERVER_URL}/assessment/scoring?programs=SAFE&locationNumbers=${locationNumber}`;
        const { data: auditSummaryData } = await API_GET(auditSummaryPath);

        if (round !== rounds[pointer]) {
          setIsMissingData(true);
          auditSummaryData.performanceHistory.push({
            round,
            auditUUID: null,
            performanceLevel: null,
          });
        }

        setAuditSummary(auditSummaryData);
      } catch (error) {
        console.error(error);
      } finally {
        setAuditByLocationLoading(false);
        setAuditSummaryLoading(false);
      }
    };
    fetchData();
  }, [locationNumber, round, rounds]);

  useEffect(() => {
    if (auditByLocationLoading || auditSummaryLoading || !auditSummary) return;
    const isUsLocation = !!auditSummary[0]?.round?.includes('Q');
    const processedData = auditSummary
      .sort(isUsLocation ? sortByQuarter : sortByMonth)
      .filter((historyRecord) => historyRecord.adjustedScore !== undefined)
      .map(({ round: r, adjustedScore }) => ({
        x: r,
        y: adjustedScore,
      }))
      .slice(0, 5)
      .reverse();

    setChartData(processedData);
  }, [auditByLocationLoading, auditSummaryLoading, auditSummary]);

  return (
    <Card data-testid="historical-performance-card-card">
      <CardContent data-testid="historical-performance-card-card-content">
        <Typography
          data-testid="historical-performance-card-title"
          variant="h4"
          gutterBottom
        >
          Food Safety Historical Performance Level
        </Typography>
        <div>
          <HistoricalPerformanceChart
            data-testid="historical-performance-card-historical-performance-chart"
            chartData={chartData}
            isLoading={auditByLocationLoading || auditSummaryLoading}
          />
          {isMissingData && <MissingDataToolTip />}
        </div>
      </CardContent>
    </Card>
  );
};

export default HistoricalPerformanceCard;
